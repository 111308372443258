<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(detail)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol md="3">
        <MDBInput :label="$t('COMMON.ORDERNUMBER')" :placeholder="$t('COMMON.ORDERNUMBER')" v-model="where.order" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.MEMBERNAME')" :placeholder="$t('COMMON.MEMBERNAME')" v-model="where.name" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:order_really_total="{ item }">
        {{ item.order_really_total }} {{item.currency}}
      </template>
      <template v-slot:order_total="{ item }">
        {{ item.order_total }} {{item.currency}}
      </template>
      <template v-slot:order_promo_total="{ item }">
        {{ item.order_promo_total }} {{item.currency}}
      </template>
      <template v-slot:payment_able="{ item }">
        {{ (item.type=="free")?0:item.payment_able }}
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:next_at="{ item }">
        {{ (item.type=="free")?"試用":((!!item.payment_at)?Common.TimeFormat(NextMonth(item.payment_at,((item.type=="bank")?item.payment_able:1))):"") }}
      </template>
      <template v-slot:payment_at="{ item }">
        <template v-if="item.payment_at">
          {{ Common.TimeFormat(item.payment_at) }}<br> ~ {{ (item.subscription_end_time)?Common.TimeFormat(item.subscription_end_time):"" }}
        </template>
      </template>
      <template v-slot:calamount="{ item }">
        {{ item.payment_count * item.really_amount }}
      </template>
      <template v-slot:status="{ item }">
        <div class="badge  text-wrap" :class="{ 'bg-danger': (item.status == 0 || item.status == 2),'bg-success': (item.status == 1) }">
          {{ $t("ORDER.STATUS_" + item.status) }}
        </div>
      </template>
      <!-- 操作template -->
      <template v-slot:uuid="{ item }">
        <a @click="DetailUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('ORDER.DETAIL')" href="javascript:void(0)" class="me-2">
          <MDBIcon icon="eye" iconStyle="fas" class="text-primary"/>
        </a>
        <template v-if="item.payment_count > 0 && ['creditPeriod'].indexOf(item.type) >=0">
          <a v-if="item.status == 4" @click="OrderAction(item.uuid, 'Cancel')" data-bs-toggle="tooltip" :title="$t('ORDER.REAUTH')" href="javascript:void(0)" class="me-2">
            <MDBIcon icon="bell" iconStyle="fas" class="text-success"/>
          </a>
          <a v-if="item.status == 1" @click="OrderAction(item.uuid, 'Cancel')" data-bs-toggle="tooltip" :title="$t('ORDER.CANCEL')" href="javascript:void(0)" class="me-2">
            <MDBIcon icon="ban" iconStyle="fas" class="text-danger"/>
          </a>
        </template>
        <template v-else>
          
        </template>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<OrderDetail v-model="detail" v-model:uuid="DetailUUid"></OrderDetail>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import OrderDetail from '@/components/OrderDetail';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import Swal from "sweetalert2";

export default {
  name: "Subscription",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
		OrderDetail,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBIcon,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const detail = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const DetailUUid = ref("");
    const desserts = ref([]);

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const where = reactive({
      order: "",
      name: "",
      email: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width:50
      },
      { text: i18n.t("COMMON.ORDERTIME"), value: "created_at",width:112 },
      { text: i18n.t("COMMON.ORDERNUMBER"), value: "order" },
      { text: "商品名稱", value: "product.name" },
      { text: i18n.t("COMMON.MEMBERNAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.SUBSCRIPTIONAMOUNT"), value: "order_really_total" },
      { text: i18n.t("COMMON.SUBSCRIPTIONNUMBER"), value: "order_count" },
      { text: "訂閱起訖日", value: "payment_at",width:200 },
      { text: i18n.t("COMMON.PAYMENTEDNUMBER"), value: "payment_count" },
      { text: i18n.t("COMMON.CUMULATIVEPAYMENTAMOUNT"), value: "calamount" },
      { text: i18n.t("COMMON.NEXTPAYMENTDATE"), value: "next_at",width:112 },
      { text: i18n.t("COMMON.OPERATE"), value: "uuid", sortable: false,width:90 }
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Subscription",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const OrderAction = function(order,action) {
      Swal.fire({
        title: i18n.t("COMMON.WARNING"),
        html: "確定要取消?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("COMMON.CONFIRM"),
        cancelButtonText: i18n.t("COMMON.CANCEL")
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.post("/Admin/v1/Subscription", { 'order':order,'action':action }).then(response => {
            if (response.status == 200) {
              CommonService.AxiosHandle(response);
              GetTable();
            }
          });
        }
      });
    };

    const NextDate = function(date,month) {
      let d = new Date(date);
      d.setDate(d.getDate()+month);
      return d.getTime();
    }

    const NextMonth = function(date,month) {
      let d = new Date(date);
      d.setMonth(d.getMonth()+month);
      return d.getTime();
    }

		return {
      PageLoading,
      detail,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      DetailUUid,
      GetTable,
      NextDate,
      NextMonth,
      OrderAction,
      where
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  },
  mounted() {
    this.GetTable();
  }
};
</script>